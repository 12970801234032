@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border m-0 p-0;
  }
  
  img {
    @apply w-full;
  }
  
  body {
    @apply font-lato leading-normal relative;
  }
}

@layer components {
  .section-center {
    @apply w-[90vw] max-w-content mx-auto;
  }
  
  @media screen and (min-width: 992px) {
    .section-center {
      @apply w-[70vw];
    }
  }
  
  .toggle-button {
    @apply absolute right-4 top-4;
  }
}
